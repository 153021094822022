import Swiper from 'swiper';
import SwiperCore, { Pagination, Navigation, Lazy, Autoplay } from 'swiper/core';
import lazySizes from "lazysizes";
import Isotope  from "isotope-layout";
import imagesLoaded from "imagesloaded";
import { concatValues, createQueryString } from "./helpers";
import PhotoSwipeLightbox from 'photoswipe/dist/photoswipe-lightbox.esm.js';
import PhotoSwipe from 'photoswipe/dist/photoswipe.esm.js';

import '@fortawesome/fontawesome-free/js/all.js';

lazySizes.cfg.init = false; // Auto init goes wrong sometimes

import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
// import SlideUpDown from 'vue-slide-up-down';
// configure Swiper to use modules
SwiperCore.use([Pagination, Navigation, Lazy, Autoplay]);

const main = async () => {
    const { default: Vue } = await import(/* webpackChunkName: "vue" */ "vue");
    const { default: axios } = await import(/* webpackChunkName: "axios" */ "axios"); // prettier-ignore
    const { default: VueCookies } = await import( /* webpackChunkName: "vue-cookies" */ "vue-cookies"); // prettier-ignore

    Vue.use(VueCookies);

    const vm = new Vue({
        el: "#site",
        delimiters: ["${", "}"],

        components: {
            "slide-up-down": () => import( /* webpackChunkName: "vue-slide-up-down" */ "vue-slide-up-down" ), // prettier-ignore
            "tabs": () => import(/* webpackChunkName: "tabs" */ "../vue/Tabs.vue") // prettier-ignore
        },

        data: {
            // Navigation
            mobileNavOffset: 0,
            mobileNavIsOpen: false,
            showNavigationOverlay: false,
            isTouchDevice: false,
            openMobileNavItems: [],
            openMobileFooterNavItems: [],

            // Notifications
            showNotificationBar: true,

            // Breakpoints
            isBreakpointNM: false,
            // CookieFirst
            // advertisingConsent: false, // used to show/hide AddThis share buttons
        },

        created() {
            window.addEventListener("resize", this.setMobileNavOffset);
        },

        mounted() {
            // Init lazysizes
            lazySizes.init();

            // Using deprecated addListener() instead of addEventListener('change')
            // because Safari does not support addEventListener on MediaQueryList
            const breakpointNM = window.matchMedia("(min-width: 1181px)");
            breakpointNM.addListener(() => {
                this.isBreakpointNM = breakpointNM.matches;
            });
            // Set initial value
            this.isBreakpointNM = breakpointNM.matches;
            // Init formie
            if (window.Formie) {
                window.Formie.initForms();
            }

            // Cookie First consents
            // this.getCookieConsents();
            // Check if it's a touch device
            if ("ontouchstart" in window || navigator.MaxTouchPoints > 0) {
                this.isTouchDevice = true;
            }
            // Set mobile nav offset
            this.setMobileNavOffset();
            // Hero swiper
            this.initHeroSwiper();
            // Images swiper
            this.initImagesSwiper();
            // Twitter carousels
            this.initTwitterSwiper();
            // Articles grid with isotope filters
            this.initArticlesGrid();
            // Team page grid
            this.initTeamGrid();
            // Photoswipe 
            this.initPhotoswipe();
            // Album grid
            this.initAlbumGrid();
        },

        beforeDestroy() {},

        methods: {
            // async getCookieConsents() {
            //     while (!window.hasOwnProperty("CookieFirst")) // define the condition as you like
            //     await new Promise(resolve => setTimeout(resolve, 1000));
                
            //     // Set consents
            //     this.advertisingConsent = window.CookieFirst?.consent?.advertising || false;
            // },
             
            initPhotoswipe() {
                const lightbox = new PhotoSwipeLightbox({
                    gallerySelector: '.photoswipe-gallery',
                    childSelector: 'a',
                    pswpModule: PhotoSwipe
                });

                lightbox.init();
            },

            initAlbumGrid() {
                const baseClass = 'grid-album';
                const masonries = document.querySelectorAll(`.${baseClass}`);

                if(!masonries.length) {
                    return;
                }

                // Loop through all masonries
                masonries.forEach(masonry => {
                    // Reuse isotope
                    const isotope = new Isotope(masonry, {
                        itemSelector: `.${baseClass}__cell`,
                        percentPosition: true,
                        masonry: {
                            gutter: `.${baseClass}__sizer`
                        }
                    });

                    // Only show grid after initial arranging
                    isotope.once('layoutComplete', () => {
                        masonry.classList.add(`${baseClass}--ready`);
                    });

                    // Trigger initial layout
                    isotope.layout();
                });
            },
            
            initArticlesGrid() {
                const masonryContainers = document.querySelectorAll('.grid-wrapper');

                for(let container of masonryContainers) {
                    // Check if we need to update the querystring
                    const baseClass = 'grid-articles';
                    const gridEl = container.querySelector(`.${baseClass}`);
                    const filterEls = container.querySelectorAll('[data-filter]');
                    const filterGroupEls = container.querySelectorAll('[data-filter-group]');
                    const useQuerystring = container.hasAttribute('data-use-querystring') ?? false;

                    // Setup basic filters object
                    const params = new URLSearchParams(window.location.search);

                    const filters = {
                        category: params.get('category') ? `.ft-category-${params.get('category')}` :  "",
                        tag: params.get('tag') ? `.ft-tag-${params.get('tag')}` :  "",
                        type: params.get('type') ? `.ft-type-${params.get('type')}` :  "",
                    };

                    // Apply isotope with masonry layout
                    const isotope = new Isotope(gridEl, {
                        itemSelector: `.${baseClass}__cell`,
                        percentPosition: true,
                        masonry: {
                            gutter: `.${baseClass}__sizer`
                        }
                    });

                    // Only show grid after initial arranging
                    isotope.once('arrangeComplete', () => {
                        gridEl.classList.add(`${baseClass}--ready`);
                    });

                    // Arrange cards by default on page load
                    arrangeCards();

                    // Check if images are loaded and redraw isotope layout
                    imagesLoaded(gridEl).on('progress', () => {
                        isotope.layout();
                    });

                    // Add click event to filters
                    filterEls.forEach(tag => {
                        tag.addEventListener('click', (e) => {
                            e.preventDefault();
                            applyFilter(tag);
                        });
                    });

                    function applyFilter(filterEl) {
                        const filterName = filterEl.getAttribute('data-filter');
                        const filterGroupEl = filterEl.closest('[data-filter-group]');
                        const filterGroupName = filterGroupEl.getAttribute('data-filter-group');
                        
                        // Push filters by filter group (make sure only one can be added)
                        filters[filterGroupName] = filters[filterGroupName] === filterName ? "" : filterName;

                        arrangeCards();
                    }

                    /* Arrange cards based on filters array */
                    function arrangeCards() {
                        // Arrange our items based on our selected filters
                        isotope.arrange({ filter: concatValues(filters) });

                        // Remove all inactive classes
                        filterEls.forEach(filterEl => filterEl.classList.remove('inactive'));

                        // Add inactive classes to unselected filters by group
                        filterGroupEls.forEach(filterGroup => {
                            const groupName = filterGroup.getAttribute('data-filter-group');

                            if(filters[groupName]) {
                                const inactiveFilters = filterGroup.querySelectorAll(`[data-filter]:not([data-filter='${filters[groupName]}'])`);
                                inactiveFilters.forEach(inactives => inactives.classList.add('inactive'));
                            }
                        });

                        // Set url
                        if(useQuerystring) {
                            const querystring = createQueryString({...{ q: params.get('q') }, ...filters});
                            const cleanUrl = location.protocol + "//" + location.host + location.pathname;
                            
                            if(querystring) {
                                history.replaceState({}, null, querystring);
                            } else {
                                history.replaceState({}, null, cleanUrl);
                            }
                        }
                    }
                }
            },

            initTeamGrid() {
                const container = document.querySelector('.grid-wrapper-team');

                if(!container) {
                    return;
                }

                const filterEls = container.querySelectorAll('[data-filter]');
                const gridEl = container.querySelector('.grid-members-group');
                let filter = '';

                const isotope = new Isotope(gridEl, {
                    itemSelector: '.grid-members',
                    percentPosition: true
                });

                // Only show grid after initial arranging
                isotope.once('arrangeComplete', () => {
                    gridEl.classList.add('grid-members-group--ready');
                });

                // Check if images are loaded and redraw isotope layout
                imagesLoaded(gridEl).on('progress', () => {
                    isotope.layout();
                });
                
                // Initial arrange
                isotope.arrange({ filter: filter });

                // Filters logic
                filterEls.forEach(filterEl => {
                    filterEl.addEventListener('click', (e) => {
                        e.preventDefault();

                        const selectedFilter = filterEl.getAttribute('data-filter');
                        // Toggle filter
                        filter = filter === selectedFilter ? "" : selectedFilter;

                        // Arrange layout based on current filter
                        isotope.arrange({ filter: filter });

                        // Remove all inactive classes
                        filterEls.forEach(filterEl => filterEl.classList.remove('inactive'));

                        if(filter) {
                            const inactiveFilters = container.querySelectorAll(`[data-filter]:not([data-filter='${filter}'])`);
                            inactiveFilters.forEach(inactives => inactives.classList.add('inactive'));
                        }
                    });
                });

            },

            initTwitterSwiper() {
                const tweetBlocks = document.querySelectorAll('.block-twitter-carousel');

                tweetBlocks.forEach(block => {
                    const swiperEl = block.querySelector('.swiper-container');
                    const prevEl = block.querySelector('.swiper-button-prev');
                    const nextEl = block.querySelector('.swiper-button-next');

                    if(!swiperEl) {
                        return;
                    }

                    const swiper = new Swiper(swiperEl, {
                        slidesPerView: 1,
                        speed: 700,
                        // loop: true,
                        // loopAdditionalSlides: 6,
                        // spaceBetween: 32,
                        navigation: {
                            prevEl,
                            nextEl
                        },
                        breakpoints: {
                            576: {
                                slidesPerView: 2
                            },
                            768: {
                                slidesPerView: 3
                            },
                            1024: {
                                slidesPerView: 4
                            }
                        }
                    });
                });
            },

            initHeroSwiper() {
                const hero = document.querySelector('.hero-carousel');

                if(!hero) {
                    return;
                }

                const swiperEl = hero.querySelector('.swiper-container');
                const paginationEl = hero.querySelector('.swiper-pagination');
                const servicesEl = hero.querySelector('.hero-carousel__services');

                const swiper = new Swiper(swiperEl, {
                    slidesPerView: 1,
                    speed: 700,
                    loop: true,
                    autoplay: {
                        delay: 5000
                    },
                    preloadImages: false,
                    watchSlidesVisibility: true,
                    lazy: true,
                    pagination: {
                        el: paginationEl,
                        clickable: true,
                        type: "bullets"
                    },
                    on: {
                        init() {
                            if(servicesEl) {
                                hero.style.setProperty('--services-offset', `${servicesEl.offsetHeight + 16}px`);
                            }
                        },
                        resize() {
                            if(servicesEl) {
                                hero.style.setProperty('--services-offset', `${servicesEl.offsetHeight + 16}px`);
                            }
                        },
                        afterInit() {
                            // Add loaded class so we can fade in our content, pagination and captions.
                            hero.classList.add('hero-carousel--loaded');
                        },
                        paginationRender() {
                            // Reposition captions when pagination is rendered
                            const captions = hero.querySelectorAll('.caption');
                            captions.forEach(caption => {
                                hero.style.setProperty('--caption-offset', `${paginationEl.offsetWidth + 16}px`);
                            });
                        }
                    }
                });
            },

            initImagesSwiper() {
                const imagesEls = document.querySelectorAll('.block-images');

                if(!imagesEls) {
                    return;
                }

                imagesEls.forEach(imageEl => {
                    const swiperEl = imageEl.querySelector('.swiper-container');
                    const paginationEl = imageEl.querySelector('.swiper-pagination');
    
                    const swiper = new Swiper(swiperEl, {
                        slidesPerView: 1,
                        speed: 700,
                        loop: true,
                        autoplay: {
                            delay: 5000
                        },
                        preloadImages: false,
                        watchSlidesVisibility: true,
                        lazy: true,
                        pagination: {
                            el: paginationEl,
                            clickable: true,
                            type: "bullets"
                        },
                        on: {
                            afterInit() {
                                // Add loaded class so we can fade in our content, pagination and captions.
                                swiperEl.classList.add('swiper-loaded');
                            },
                            paginationRender() {
                                // Reposition captions when pagination is rendered
                                const captions = imageEl.querySelectorAll('.caption');
                                captions.forEach(caption => {
                                    imageEl.style.setProperty('--caption-offset', `${paginationEl.offsetWidth + 16}px`);
                                });
                            }
                        }
                    });
                })

            },

            setMobileNavOffset() {
                // prettier-ignore
                this.mobileNavOffset = this.$refs.siteHeader ? this.$refs.siteHeader.clientHeight : 0;
            },

            closeNotificationBar(slug) {
                VueCookies.set(`notification-${slug}`, "hidden", "7d");
                this.showNotificationBar = false;
                Vue.nextTick(() => {
                    // On next tick change top offset
                    this.setMobileNavOffset();
                });
            },

            /* Mobile nav toggling */

            toggleMobileNavItem(id) {
                if (this.isMobileNavOpen(id)) {
                    // Remove current id from array
                    this.openMobileNavItems = this.openMobileNavItems.filter(
                        (i) => i !== id
                    );
                    return;
                }

                this.openMobileNavItems.push(id);
            },

            isMobileNavOpen: function (id) {
                return this.openMobileNavItems.includes(id);
            },

            toggleMobileNav() {
                this.mobileNavIsOpen = !this.mobileNavIsOpen;
                this.setBodyLock();
            },

            setBodyLock() {
                // Enable/disable body scrolling
                if(this.$refs.navMobile) {
                    this.mobileNavIsOpen && !this.isBreakpointNM ? disableBodyScroll(this.$refs.navMobile) : enableBodyScroll(this.$refs.navMobile);
                }
            },

            /* Footer mobile nav toggling */

            toggleFooterMobileNavItem(id) {
                // Don't toggle when on desktop
                if(this.isBreakpointNM) {
                    return;
                }

                if (this.isMobileNavOpen(id)) {
                    // Remove current id from array
                    this.openMobileNavItems = this.openMobileNavItems.filter(
                        (i) => i !== id
                    );
                    return;
                }

                this.openMobileNavItems.push(id);
            },

            // Show footer mobile nav if it's open OR if we are on desktop
            isFooterMobileNavOpen: function (id) {
                return this.openMobileNavItems.includes(id) || this.isBreakpointNM;
            },

            // Dropdown overlay
            showDropdownOverlay(e) {
                const item = e.currentTarget;
                const hasChildren = item.classList.contains('has-children');
                // Prevent events if the dropdown is not open or if there is no dropdown element available
                if (!item.classList.contains("is-tapped") && hasChildren) {
                    e.preventDefault();
                }
                
                if (hasChildren) {
                    item.classList.add("is-tapped");
                    this.showNavigationOverlay = true;
                }
            },

            hideDropdownOverlay(e) {
                const item = e.currentTarget;
                item.classList.remove("is-tapped");

                this.showNavigationOverlay = false;
            },
        },

        computed: {},
        watch: {
            isBreakpointNM: function () {
                // Whenever we resize our window with the navigation open we want to lock/unlock our navigation based on screenregion
                this.setBodyLock();
            },
        },
    });

    return vm;
};

// Execute async function
main().then((vm) => {});

// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
    module.hot.accept();
}
