const concatValues = (obj) => {
    return Object.values(obj).join('');
};

const createQueryString = (params) => {
    // Join params key-values into queryString
    let queryString = '';
    if (Object.keys(params).length !== 0) {
        for (let [key, value] of Object.entries(params)) {
            // Check if there is a value + split filter prefix
            if(value) {
                queryString = `${ queryString }&${ key }=${ value.replace('.ft-category-','').replace('.ft-type-','').replace('.ft-tag-','') }`
            }
        }
        // Replace leading '&' by '?' -> replace by default only replaces first occurrence
        queryString = queryString.replace('&', '?');
    }

    return queryString;
}

export {
    concatValues,
    createQueryString
};
